<template>
  <div class="objective-modal">
    <div class="modal fade" data-bs-backdrop="static" id="objectiveDeleteModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div v-if="elementType" class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ vocabulary[elementType].text }} löschen</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="text-center my-3">
              <p v-if="elementType === 'objective'" class="fw-bold">{{ this.selectedObjective.name }}</p>
              <p>Möchten Sie {{ vocabulary[elementType].det }} {{ vocabulary[elementType].text }} wirklich löschen?</p>
            </div>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
            <button @click="deleteElement()" type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal">{{ $t("general.delete") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObjectiveDeleteModal',
  data () {
    return {
      vocabulary: {
        objective: {
          text: 'Ziel',
          det: 'das'
        },
        record: {
          text: 'Ist- und Forecast-Werte',
          det: 'die'
        },
        review: {
          text: 'Review',
          det: 'das'
        },
        measure: {
          text: 'Maßnahme',
          det: 'die'
        },
        conflict: {
          text: 'Konflikt',
          det: 'den'
        },
        trial: {
          text: 'Lösungsansatz',
          det: 'den'
        },
        link: {
          text: 'Link',
          det: 'den'
        },
        note: {
          text: 'Notiz',
          det: 'die'
        }
      }
    }
  },
  computed: {
    selectedObjective () {
      return this.$store.state.selectedObjective
    },
    elementId () {
      return this.$parent.objectiveModalElementId
    },
    elementType () {
      return this.$parent.objectiveModalElementType
    }
  },
  methods: {
    async deleteElement () {
      try {
        this.$store.commit('SET_LOADING_STATUS', true)
        if (this.elementType === 'objective' && !this.selectedObjective.hasNodes) {
          await this.$store.dispatch('deleteObjective', this.selectedObjective.id)
        } else if (this.elementType === 'record') {
          await this.$store.dispatch('deleteObjectiveElement', { objectiveId: this.selectedObjective.id, elementType: 'keyresults/' + this.$parent.objectiveModalKeyResultId + '/records', elementId: this.elementId })
        } else {
          await this.$store.dispatch('deleteObjectiveElement', { objectiveId: this.selectedObjective.id, elementType: (this.elementType + 's'), elementId: this.elementId })
        }
      } finally {
        this.$parent.$parent.objectivesTreeView = 'org'
        await this.$store.dispatch('getObjectives', { validFrom: this.$parent.$parent.filterValidFrom, validTo: this.$parent.$parent.filterValidTo, treeView: 'org' })
        this.$store.commit('SET_LOADING_STATUS', false)
      }
    }
  }
}
</script>
