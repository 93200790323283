<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3 sidebar-monitoring bg-custom-blue pb-5">
        <div class="row mt-3">
          <div class="col-12">
            <div class="dropdown">
              <button class="btn btn-sm btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>{{ new Date(filterValidFrom).toLocaleDateString({dateStyle: 'short'}) }} &nbsp;-&nbsp; {{ new Date(filterValidTo).toLocaleDateString({dateStyle: 'short'}) }}</span>
              </button>
              <div class="dropdown-menu border-secondary p-3">
                <div class="row">
                  <div class="col-12">
                    <button @click="filterKPIs(new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10))" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ $t("general.today") }}</button>
                    <button @click="filterKPIs((new Date().getFullYear() + 1).toString() + '-01-01', (new Date().getFullYear() + 1).toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() + 1 }}</button>
                    <button @click="filterKPIs(new Date().getFullYear().toString() + '-01-01', new Date().getFullYear().toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() }}</button>
                    <button @click="filterKPIs((new Date().getFullYear() - 1).toString() + '-01-01', (new Date().getFullYear() - 1).toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() - 1 }}</button>
                  </div>
                </div>
                <hr class="dropdown-divider">
                <div class="row">
                  <div class="col-6">
                    <label for="filterValidFrom" class="form-label">{{ $t("views.kpi.periods.from") }}:</label>
                    <input v-model="filterValidFrom" type="date" class="form-control form-control-sm" id="filterValidFrom">
                  </div>
                  <div class="col-6">
                    <label for="filterValidTo" class="form-label">{{ $t("views.kpi.periods.to") }}:</label>
                    <input v-model="filterValidTo" type="date" class="form-control form-control-sm" id="filterValidTo">
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button @click="filterKPIs(filterValidFrom, filterValidTo)" class="btn btn-sm btn-outline-primary mt-3" type="button">{{ $t("views.kpi.periods.filter") }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <input v-model="searchText" type="text" :placeholder="$t('general.placeholderSearch')" class="form-control form-control-sm border-dark" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <KPITree v-if="'nodes' in kpis && kpis.nodes.length" :node="kpis" :depth="0" mode="cockpit" :searchText="searchText" class="mt-2"></KPITree>
          </div>
        </div>
      </div>
      <div class="col-9 px-5 py-3">
        <div v-if="selectedKPIs.length" class="row">
          <div v-for="kpi in selectedKPIs" :key="kpi.id" class="col-6 mb-4">
            <KPIPeriods :kpi="kpi"></KPIPeriods>
          </div>
        </div>
        <div v-else class="text-center mt-5">
          <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
          <p class="h5 mt-3">{{ $t("views.kpi.structure.helpChooseKPI") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KPITree from '../../components/monitoring/KPITree.vue'
import KPIPeriods from '../../components/monitoring/KPIPeriods.vue'

export default {
  name: 'MonitoringKPI',
  data () {
    return {
      filterValidFrom: this.$store.state.user?.settings?.favorites?.kpiView?.filterValidFrom ? this.$store.state.user.settings.favorites.kpiView.filterValidFrom : new Date().getFullYear().toString() + '-01-01',
      filterValidTo: this.$store.state.user?.settings?.favorites?.kpiView?.filterValidTo ? this.$store.state.user.settings.favorites.kpiView.filterValidTo : (new Date().getFullYear() + 1).toString() + '-12-31',
      searchText: ''
    }
  },
  components: {
    KPITree,
    KPIPeriods
  },
  computed: {
    kpis () {
      return this.$store.state.kpis.constructor === Array ? [] : this.$store.state.kpis
    },
    selectedKPIs () {
      return this.$store.state.selectedKPIs
    }
  },
  methods: {
    async filterKPIs (filterFrom, filterTo) {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.filterValidFrom = filterFrom
      this.filterValidTo = filterTo
      await this.$store.dispatch('getKPIs', { validFrom: filterFrom, validTo: filterTo })
      await this.$store.dispatch('favoriteObject', { isList: false, objectKey: 'kpiView', objectValue: { filterValidFrom: filterFrom, filterValidTo: filterTo } })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  async created () {
    this.$store.commit('SET_LOADING_STATUS', true)
    await this.$store.dispatch('getKPIs', { validFrom: this.filterValidFrom, validTo: this.filterValidTo })
    const curSelectedKPIs = this.selectedKPIs.reverse()
    this.$store.state.selectedKPIs = []
    if (curSelectedKPIs.length) {
      await curSelectedKPIs.forEach(curSelectedKPI => {
        this.$store.dispatch('getKPI', { mode: 'cockpit', id: curSelectedKPI.id })
      })
    } else if (this.$store.state.user.settings?.favorites?.kpis) {
      await this.$store.state.user.settings?.favorites?.kpis.forEach(kpi => {
        this.$store.dispatch('getKPI', { mode: 'cockpit', id: kpi })
      })
    }
    await this.$store.dispatch('getKPIUnits')
    this.$store.commit('SET_LOADING_STATUS', false)
  }
}
</script>
