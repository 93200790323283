import { createStore } from 'vuex'
import { HTTP } from '../http-common'
import Cookies from 'js-cookie'
import router from '../router'
import i18n from '../main'
import * as tree from '../composables/tree'

export default createStore({
  state: {
    tenantMode: process.env.VUE_APP_TENANT_MODE,
    tenantName: process.env.VUE_APP_TENANT_NAME,
    alert: false,
    loadingStatus: false,
    loginStatus: false,
    user: {},
    userOrgs: [],
    userOrg: '',
    tenant: {},
    objectives: {},
    objectivesList: [],
    objectivePermissions: [],
    selectedObjective: {},
    newObjective: { mentor: '' },
    objectivesContentView: 'detail',
    xMatrix: '',
    kpis: {},
    kpisList: [],
    kpiPermissions: [],
    kpiUnits: [],
    kpiChanges: [],
    kpiDeviations: [],
    kpiRecordMode: 'actual',
    selectedKPIs: [],
    selectedKPI: {},
    selectedKPIRecords: {},
    orgUnits: {},
    orgUnitsList: [],
    orgUnitPermissions: [],
    selectedOrgUnit: {},
    employees: [],
    selectedEmployee: {},
    projects: {},
    projectsList: [],
    projectPermissions: [],
    selectedProject: {}
  },
  mutations: {
    SET_LOADING_STATUS (state, loadingStatus) {
      state.loadingStatus = loadingStatus
    },
    ADD_ALERT (state, alert) {
      state.alert = alert
    },
    DELETE_ALERT (state) {
      state.alert = false
    },
    SET_LOGIN_STATUS (state) {
      state.loginStatus = true
    },
    SET_USER (state, user) {
      state.user = user
    },
    SET_USER_ORGS (state, userOrgs) {
      state.userOrgs = userOrgs
    },
    SET_USER_ORG (state, userOrg) {
      state.userOrg = userOrg
    },
    SET_USER_FAVORITES (state, favorites) {
      state.user.settings.favorites = favorites
    },
    SET_TENANT (state, tenant) {
      state.tenant = tenant
    },
    SET_OBJECTIVES (state, objectives) {
      state.objectives = objectives
      state.objectivesList = tree.treeToList(objectives.nodes)
    },
    SET_OBJECTIVE_PERMISSIONS (state, permissions) {
      state.objectivePermissions = permissions
    },
    SET_SELECTED_OBJECTIVE (state, selectedObjective) {
      state.selectedObjective = selectedObjective
    },
    SET_NEW_OBJECTIVE (state, newObjective) {
      state.newObjective = newObjective
    },
    SET_OBJECTIVES_CONTENT_VIEW (state, objectivesContentView) {
      state.objectivesContentView = objectivesContentView
    },
    SET_X_MATRIX (state, xMatrix) {
      state.xMatrix = xMatrix
    },
    SET_KPIS (state, kpis) {
      state.kpis = kpis
      state.kpisList = tree.treeToList(kpis.nodes)
    },
    SET_KPI_PERMISSIONS (state, permissions) {
      state.kpiPermissions = permissions
    },
    SET_KPI_UNITS (state, kpiUnits) {
      state.kpiUnits = kpiUnits
    },
    SET_KPI_CHANGES (state, kpiChanges) {
      state.kpiChanges = kpiChanges
    },
    SET_KPI_DEVIATIONS (state, kpiDeviations) {
      state.kpiDeviations = kpiDeviations
    },
    SET_KPI_RECORD_MODE (state, kpiRecordMode) {
      state.kpiRecordMode = kpiRecordMode
    },
    SET_SELECTED_KPIS (state, selectedKPIs) {
      state.selectedKPIs = selectedKPIs
    },
    SET_SELECTED_KPI (state, selectedKPI) {
      state.selectedKPI = selectedKPI
    },
    SET_SELECTED_KPI_RECORDS (state, selectedKPIRecords) {
      state.selectedKPIRecords = selectedKPIRecords
    },
    SET_PROJECTS (state, projects) {
      state.projects = projects
      state.projectsList = tree.treeToList(projects.nodes)
    },
    SET_PROJECT_PERMISSIONS (state, permissions) {
      state.projectPermissions = permissions
    },
    SET_SELECTED_PROJECT (state, selectedProject) {
      state.selectedProject = selectedProject
    },
    SET_ORG_UNITS (state, orgUnits) {
      state.orgUnits = orgUnits
      state.orgUnitsList = tree.treeToList(orgUnits.nodes)
    },
    SET_ORG_UNIT_PERMISSIONS (state, permissions) {
      state.orgUnitPermissions = permissions
    },
    SET_SELECTED_ORG_UNIT (state, orgUnit) {
      state.selectedOrgUnit = orgUnit
    },
    SET_EMPLOYEES (state, employees) {
      state.employees = employees
    },
    SET_SELECTED_EMPLOYEE (state, employee) {
      state.selectedEmployee = employee
    }
  },
  actions: {
    initialiseApp ({ commit, state }) {
      return new Promise((resolve) => {
        HTTP.get('set-csrf', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
        HTTP.get('check-session', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } }).then(() => {
          if (localStorage.getItem('focusLoginStatus')) {
            commit('SET_LOGIN_STATUS', localStorage.getItem('focusLoginStatus'))
            commit('SET_USER', JSON.parse(localStorage.getItem('focusUser')))
            commit('SET_USER_ORGS', JSON.parse(localStorage.getItem('focusUserOrgs')))
            commit('SET_USER_ORG', localStorage.getItem('focusUserOrg'))
            commit('SET_TENANT', JSON.parse(localStorage.getItem('focusTenant')))
          }
        }, () => {
          return resolve()
        }).finally(() => {
          return resolve()
        })
      })
    },
    register ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('register', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_LOGIN_STATUS', true)
            commit('SET_USER', response.data.user)
            commit('SET_USER_ORGS', response.data.orgs)
            commit('SET_USER_ORG', response.data.org)
            commit('SET_TENANT', response.data.tenant)
            commit('SET_OBJECTIVES_CONTENT_VIEW', 'add')
            localStorage.setItem('focusLoginStatus', true)
            localStorage.setItem('focusUser', JSON.stringify(response.data.user))
            localStorage.setItem('focusUserOrgs', JSON.stringify(response.data.orgs))
            localStorage.setItem('focusUserOrg', response.data.org)
            localStorage.setItem('focusTenant', JSON.stringify(response.data.tenant))
            router.push({ name: 'ObjectivesManageObjective' })
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getOTP ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('set-otp', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            localStorage.setItem('focusLoginEmail', payload.email)
            return resolve(true)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
            return resolve(false)
          })
      })
    },
    login ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('login', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_LOGIN_STATUS', true)
            commit('SET_USER', response.data.user)
            commit('SET_USER_ORGS', response.data.orgs)
            commit('SET_USER_ORG', response.data.org)
            commit('SET_TENANT', response.data.tenant)
            localStorage.setItem('focusLoginStatus', true)
            localStorage.setItem('focusUser', JSON.stringify(response.data.user))
            localStorage.setItem('focusUserOrgs', JSON.stringify(response.data.orgs))
            localStorage.setItem('focusUserOrg', response.data.org)
            localStorage.setItem('focusTenant', JSON.stringify(response.data.tenant))
            router.push({ name: 'ObjectivesManageObjective' })
            return resolve(response.data.user.settings.language)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
            return resolve()
          })
      })
    },
    logout ({ commit }) {
      return new Promise((resolve) => {
        HTTP.post('logout', {}, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(() => {
            commit('SET_LOGIN_STATUS', false)
            commit('SET_USER', '')
            commit('SET_USER_ORGS', '')
            commit('SET_USER_ORG', '')
            commit('SET_TENANT', '')
            localStorage.removeItem('focusLoginStatus')
            localStorage.removeItem('focusUser')
            localStorage.removeItem('focusUserOrgs')
            localStorage.removeItem('focusUserOrg')
            localStorage.removeItem('focusTenant')
            location.reload()
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    setOrg ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('set-org', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_USER_ORGS', response.data.orgs)
            commit('SET_USER_ORG', response.data.org)
            localStorage.setItem('focusUserOrgs', JSON.stringify(response.data.orgs))
            localStorage.setItem('focusUserOrg', response.data.org)
            router.go()
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateUserDetails ({ commit, state }, payload) {
      return new Promise((resolve) => {
        HTTP.post('users/' + state.user.id + '/', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_USER', response.data.user)
            localStorage.setItem('focusUser', JSON.stringify(response.data.user))
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    favoriteObject ({ commit, state }, payload) {
      return new Promise((resolve) => {
        HTTP.put('employees/favorites', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_USER_FAVORITES', response.data.favorites)
            localStorage.setItem('focusUser', JSON.stringify(state.user))
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getTenant ({ commit, state }) {
      return new Promise((resolve) => {
        HTTP.get('tenants/' + state.userOrgs.filter(org => { return org.active })[0].id + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_TENANT', response.data.tenant)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateTenantSubscription ({ commit, state }, payload) {
      return new Promise((resolve) => {
        HTTP.put('tenants/' + state.userOrgs.filter(org => { return org.active })[0].id + '/', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_TENANT', response.data.tenant)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getPermissions ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('permissions?model=' + payload.model + '&employee=' + payload.employee, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit(payload.commit, response.data.permissions)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    addPermission ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('permissions?mode=' + payload.mode + '&model=' + payload.model + '&employee=' + payload.employee, payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            if (payload.mode === 'default') {
              commit(payload.commit, response.data.permissions)
            }
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    deletePermission ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.delete('permissions/' + payload.permissionId + '/?model=' + payload.model + '&employee=' + payload.employee, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit(payload.commit, response.data.permissions)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getObjectives ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('objectives?validFrom=' + payload.validFrom + '&validTo=' + payload.validTo + '&view=' + payload.treeView, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_OBJECTIVES', response.data.objectives)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getObjective ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('objectives/' + payload + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_OBJECTIVE', response.data.objective)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    addObjective ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('objectives', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_OBJECTIVE', response.data.objective)
            commit('SET_NEW_OBJECTIVE', {})
            commit('SET_OBJECTIVES_CONTENT_VIEW', 'detail')
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateObjective ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.put('objectives/' + payload.id + '/', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_OBJECTIVE', response.data.objective)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    deleteObjective ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.delete('objectives/' + payload + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_OBJECTIVES', response.data.objectives)
            commit('SET_SELECTED_OBJECTIVE', {})
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    addObjectiveElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('objectives/' + payload.objectiveId + '/' + payload.elementType, payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_OBJECTIVE', response.data.objective)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateObjectiveElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.put('objectives/' + payload.objectiveId + '/' + payload.elementType + '/' + payload.id + '/', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_OBJECTIVE', response.data.objective)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    deleteObjectiveElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.delete('objectives/' + payload.objectiveId + '/' + payload.elementType + '/' + payload.elementId + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_OBJECTIVE', response.data.objective)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateObjectivesContentView ({ commit }, payload) {
      commit('SET_OBJECTIVES_CONTENT_VIEW', payload)
    },
    addObjectiveKPI ({ commit, state }, payload) {
      const newObjective = state.newObjective
      if (!('kpis' in newObjective)) {
        newObjective.kpis = []
      }
      if (!newObjective.kpis.find(kpi => kpi.id === payload.kpi.id)) {
        newObjective.kpis.push(payload.kpi)
        commit('SET_NEW_OBJECTIVE', newObjective)
      }
    },
    deleteObjectiveKPI ({ commit, state }, payload) {
      const newObjective = state.newObjective
      newObjective.kpis.splice(newObjective.kpis.map(kpi => kpi.id).indexOf(payload.kpiId), 1)
      commit('SET_NEW_OBJECTIVE', newObjective)
    },
    getKPIs ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('kpis?validFrom=' + payload.validFrom + '&validTo=' + payload.validTo, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_KPIS', response.data.kpis)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getKPI ({ commit, state }, payload) {
      return new Promise((resolve) => {
        HTTP.get('kpis/' + payload.id + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_KPI', response.data.kpi)
            if (payload.mode === 'cockpit') {
              const selectedKPIs = state.selectedKPIs
              selectedKPIs.push(response.data.kpi)
              commit('SET_SELECTED_KPIS', selectedKPIs)
            }
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    addKPI ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('kpis', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_KPI', response.data.kpi)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateKPI ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.put('kpis/' + payload.id + '/', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_KPI', response.data.kpi)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    deleteKPI ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.delete('kpis/' + payload + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_KPIS', response.data.kpis)
            commit('SET_SELECTED_KPI', {})
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    deleteSelectedKPI ({ commit, state }, payload) {
      const selectedKPIs = state.selectedKPIs
      selectedKPIs.splice(selectedKPIs.map(kpi => kpi.id).indexOf(payload.kpiId), 1)
      commit('SET_SELECTED_KPIS', selectedKPIs)
    },
    getKPIUnits ({ commit }) {
      return new Promise((resolve) => {
        HTTP.get('kpis/units', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_KPI_UNITS', response.data.kpiUnits)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    addKPIUnit ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('kpis/units', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_KPI_UNITS', response.data.kpiUnits)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateKPIUnit ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.put('kpis/units/' + payload.id + '/', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_KPI_UNITS', response.data.kpiUnits)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    deleteKPIUnit ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.delete('kpis/units/' + payload + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_KPI_UNITS', response.data.kpiUnits)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getKPIChanges ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('kpis/changes?year=' + payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_KPI_CHANGES', response.data.kpiChanges)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getKPIDeviations ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('kpis/deviations?year=' + payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_KPI_DEVIATIONS', response.data.kpiDeviations)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    addKPIElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('kpis/' + payload.kpiId + '/' + payload.elementType, payload.element, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_KPI', response.data.kpi)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateKPIElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.put('kpis/' + payload.kpiId + '/' + payload.elementType + '/' + payload.element.id + '/', payload.element, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_KPI', response.data.kpi)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    deleteKPIElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.delete('kpis/' + payload.kpiId + '/' + payload.elementType + '/' + payload.elementId + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_KPI', response.data.kpi)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getKPIsRecord ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('kpis/records?year=' + payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_KPIS', response.data.kpis)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getKPIRecords ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('kpis/' + payload.kpiId + '/records/?year=' + payload.selectedYear, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_KPI_RECORDS', response.data.kpiRecords)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateKPIRecords ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.put('kpis/' + payload.id + '/records/?year=' + payload.selectedYear, payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(() => {
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    setKPIRecordMode ({ commit }, payload) {
      commit('SET_KPI_RECORD_MODE', payload)
    },
    getOrgUnits ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('org-units?validFrom=' + payload.validFrom + '&validTo=' + payload.validTo, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_ORG_UNITS', response.data.orgUnits)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getOrgUnit ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('org-units/' + payload + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_ORG_UNIT', response.data.orgUnit)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    addOrgUnit ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('org-units', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then((response) => {
            commit('SET_SELECTED_ORG_UNIT', response.data.orgUnit)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateOrgUnit ({ commit, state }, payload) {
      return new Promise((resolve) => {
        HTTP.put('org-units/' + state.selectedOrgUnit.id + '/', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_ORG_UNIT', response.data.orgUnit)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    deleteOrgUnit ({ commit, state }) {
      return new Promise((resolve) => {
        HTTP.delete('org-units/' + state.selectedOrgUnit.id + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_ORG_UNITS', response.data.orgUnits)
            commit('SET_SELECTED_ORG_UNIT', {})
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    addOrgUnitElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('org-units/' + payload.orgUnitId + '/' + payload.elementType, payload.element, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_ORG_UNIT', response.data.orgUnit)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateOrgUnitElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.put('org-units/' + payload.orgUnitId + '/' + payload.elementType + '/' + payload.element.id + '/', payload.element, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_ORG_UNIT', response.data.orgUnit)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    deleteOrgUnitElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.delete('org-units/' + payload.orgUnitId + '/' + payload.elementType + '/' + payload.elementId + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_ORG_UNIT', response.data.orgUnit)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getXMatrix ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('org-units/' + payload.orgUnit + '/x-matrix?year=' + payload.year, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_X_MATRIX', response.data.xMatrix)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getEmployees ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('employees?mode=' + payload.mode, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_EMPLOYEES', response.data.employees)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getEmployee ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('employees/' + payload + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_EMPLOYEE', response.data.employee)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    addEmployee ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('employees', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_EMPLOYEE', response.data.employee)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateEmployee ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.put('employees/' + payload.id + '/', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_EMPLOYEE', response.data.employee)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    deleteEmployee ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.delete('employees/' + payload + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_EMPLOYEES', response.data.employees)
            commit('SET_SELECTED_EMPLOYEE', {})
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getProjects ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('projects?validFrom=' + payload.validFrom + '&validTo=' + payload.validTo, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_PROJECTS', response.data.projects)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    getProject ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.get('projects/' + payload + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_PROJECT', response.data.project)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    addProject ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('projects', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_PROJECT', response.data.project)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateProject ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.put('projects/' + payload.id + '/', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_PROJECT', response.data.project)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    deleteProject ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.delete('projects/' + payload + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_PROJECTS', response.data.projects)
            commit('SET_SELECTED_PROJECT', {})
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    addProjectElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.post('projects/' + payload.projectId + '/' + payload.elementType, payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_PROJECT', response.data.project)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    updateProjectElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.put('projects/' + payload.projectId + '/' + payload.elementType + '/' + payload.id + '/', payload, { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_PROJECT', response.data.project)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    },
    deleteProjectElement ({ commit }, payload) {
      return new Promise((resolve) => {
        HTTP.delete('projects/' + payload.projectId + '/' + payload.elementType + '/' + payload.elementId + '/', { headers: { common: { 'X-CSRFToken': Cookies.get('csrftoken') } } })
          .then(response => {
            commit('SET_SELECTED_PROJECT', response.data.project)
          }, (error) => {
            const message = error.response ? i18n?.global.t('error.' + error.response.data.errorMessage) : i18n?.global.t('error.general')
            commit('ADD_ALERT', { category: 'Error', message })
            setTimeout(() => { commit('DELETE_ALERT') }, 3500)
          }).finally(() => {
            return resolve()
          })
      })
    }
  }
})
