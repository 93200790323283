<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3 sidebar-monitoring bg-custom-blue py-3">
        <div class="row">
          <div class="col-12">
            <button @click="openModal(false, 'kpi', 'add')" type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#kpiEditModal">+ {{ $t("general.add") }}</button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="dropdown">
              <button class="btn btn-sm btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>{{ new Date(filterValidFrom).toLocaleDateString({dateStyle: 'short'}) }} &nbsp;-&nbsp; {{ new Date(filterValidTo).toLocaleDateString({dateStyle: 'short'}) }}</span>
              </button>
              <div class="dropdown-menu border-secondary p-3">
                <div class="row">
                  <div class="col-12">
                    <button @click="filterKPIs(new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10))" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ $t("general.today") }}</button>
                    <button @click="filterKPIs((new Date().getFullYear() + 1).toString() + '-01-01', (new Date().getFullYear() + 1).toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() + 1 }}</button>
                    <button @click="filterKPIs(new Date().getFullYear().toString() + '-01-01', new Date().getFullYear().toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() }}</button>
                    <button @click="filterKPIs((new Date().getFullYear() - 1).toString() + '-01-01', (new Date().getFullYear() - 1).toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() - 1 }}</button>
                  </div>
                </div>
                <hr class="dropdown-divider">
                <div class="row">
                  <div class="col-6">
                    <label for="filterValidFrom" class="form-label">{{ $t("views.kpi.periods.from") }}:</label>
                    <input v-model="filterValidFrom" type="date" class="form-control form-control-sm" id="filterValidFrom">
                  </div>
                  <div class="col-6">
                    <label for="filterValidTo" class="form-label">{{ $t("views.kpi.periods.to") }}:</label>
                    <input v-model="filterValidTo" type="date" class="form-control form-control-sm" id="filterValidTo">
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button @click="filterKPIs(filterValidFrom, filterValidTo)" class="btn btn-sm btn-outline-primary mt-3" type="button">{{ $t("views.kpi.periods.filter") }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <input v-model="searchText" type="text" :placeholder="$t('general.placeholderSearch')" class="form-control form-control-sm border-dark" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <KPITree v-if="'nodes' in kpis && kpis.nodes.length" :node="kpis" :depth="0" mode="manage" :searchText="searchText" class="mt-2"></KPITree>
          </div>
        </div>
      </div>
      <div class="col-9 px-5 py-3">
        <div v-if="Object.keys(selectedKPI).length" class="row">
          <div class="col-12">
            <div class="row">
              <div class="col">
                <button v-if="selectedKPI.write" @click="openModal(selectedKPI.id, 'kpi', 'update')" type="button" class="btn btn-sm btn-outline-dark" data-bs-toggle="modal" data-bs-target="#kpiEditModal">&bull; {{ $t("general.update") }}</button>
              </div>
              <div class="col text-end">
                <DocsInfoLink color="dark" target="manageKPIs"></DocsInfoLink>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="h3">{{ selectedKPI.name }}</div>
                <div>
                  {{ $t("views.kpi.structure.validityPeriod") }}:
                  <span v-if="selectedKPI.validFrom">
                    {{ new Date(selectedKPI.validFrom).toLocaleDateString({dateStyle: 'short'}) }} {{ $t("general.until") }} {{ new Date(selectedKPI.validTo).toLocaleDateString({dateStyle: 'short'}) }}
                  </span>
                  <span v-else class="fst-italic">
                    {{ $t("views.kpi.structure.infinite") }}
                  </span>
                </div>
                <div v-if="!selectedKPI.folder">{{ $t("views.kpi.edit.responsible") }}: {{ selectedKPI.responsible.name }}</div>
              </div>
            </div>
            <div v-if="!selectedKPI.folder" class="row mt-3">
              <div class="col-3">
                <div class="card">
                  <div class="card-header text-truncate">
                    {{ $t("views.kpi.edit.unit") }}: {{ unitDisplay(false, 'long') }}
                  </div>
                  <div class="card-body text-center py-1">
                    <div class="mt-1">{{ selectedKPI.displayedPeriod ? selectedKPI.displayedPeriod : new Date().getFullYear() }}</div>
                    <KPIPeriodSingleChart :key="'kpi-chart-single-' + kpiKeyInt + selectedKPI.id + curRecord().period" :chartId="'kpiChartSingle' + kpiKeyInt + selectedKPI.id + curRecord().period" :period="curRecord()" class="kpi-chart"></KPIPeriodSingleChart>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-6">
                        <button class="btn btn-sm btn-outline-primary" :class="!selectedKPI.records ? 'disabled' : ''" data-bs-toggle="modal" :data-bs-target="'#kpiPeriodsModal' + selectedKPI.id">{{ $t("views.kpi.detail.viewKPI") }}</button>
                      </div>
                      <div class="col-4 offset-2 text-end my-auto">
                        <div class="progress position-relative border bg-dark border-dark">
                          <span class="progress-label position-absolute start-50 top-50 text-light fw-bold">{{ getProgressStatus }}%</span>
                          <div class="progress-bar progress-bar-striped" :class="getProgressStatus >= 100 ? 'bg-success' : 'bg-danger'" :style="'width: ' + getProgressStatus + '%'" role="progressbar" :aria-valuenow="getProgressStatus" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!selectedKPI.folder" class="row mt-3">
              <div class="col-12">
                <div class="card">
                  <div class="card-header fw-bold">{{ $t("views.kpi.detail.headingCard") }}</div>
                  <div class="card-body">
                    <ul class="nav nav-tabs border-bottom" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark active" id="definition-tab" data-bs-toggle="tab" data-bs-target="#definition" type="button" role="tab" aria-controls="definition" aria-selected="false">{{ $t("views.kpi.detail.tabDefinition") }}</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark" id="formulas-tab" data-bs-toggle="tab" data-bs-target="#formulas" type="button" role="tab" aria-controls="formulas" aria-selected="false">{{ $t("views.kpi.detail.tabFormulas") }} <span class="badge bg-dark">{{ selectedKPI.formulas.length }}</span></button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark" id="objectives-tab" data-bs-toggle="tab" data-bs-target="#objectives" type="button" role="tab" aria-controls="objectives" aria-selected="false">{{ $t("views.kpi.detail.tabObjectives") }} <span class="badge bg-dark">{{ selectedKPI.objectives.length }}</span></button>
                      </li>
                    </ul>
                    <div class="tab-content mt-2">
                      <div class="tab-pane fade show active" id="definition" role="tabpanel">
                        <div class="row mt-3">
                          <div class="col">
                            <ul class="list-group list-group-horizontal">
                              <li v-if="selectedKPI.aggregation" class="list-group-item">
                                <svg class="bi mb-1" width="22" height="22" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#bezier"/>
                                </svg>
                                {{ vocabulary.aggregation[selectedKPI.aggregation] }}
                              </li>
                              <li v-if="selectedKPI.statusRed" class="list-group-item">
                                <svg class="bi mb-1" width="22" height="22" fill="currentColor">
                                  <use class="text-danger" xlink:href="@/assets/icons/icons.svg#circle-fill"/>
                                </svg>
                                {{ $t("views.kpi.edit.at") }} {{ statusRedRelationalOperator }} {{ selectedKPI.statusRed }}%
                              </li>
                              <li v-if="selectedKPI.statusYellow" class="list-group-item">
                                <svg class="bi mb-1" width="22" height="22" fill="currentColor">
                                  <use class="text-warning" xlink:href="@/assets/icons/icons.svg#circle-fill"/>
                                </svg>
                                {{ $t("views.kpi.edit.at") }} {{ statusYellowRelationalOperator }} {{ selectedKPI.statusYellow }}%
                              </li>
                              <li v-if="selectedKPI.timeDimInput" class="list-group-item">
                                <svg class="bi mb-1" width="22" height="22" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#calendar"/>
                                </svg>
                                {{ $t("views.kpi.detail.input") }}: {{ vocabulary.timedim[selectedKPI.timeDimInput] }}
                              </li>
                              <li v-if="selectedKPI.timeDimDisplay" class="list-group-item">
                                <svg class="bi mb-1" width="22" height="22" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#calendar"/>
                                </svg>
                                {{ $t("views.kpi.detail.display") }}: {{ vocabulary.timedim[selectedKPI.timeDimDisplay] }}
                              </li>
                            </ul>
                            <ul v-if="selectedKPI.description" class="list-group list-group-horizontal mt-3">
                              <li class="list-group-item">
                                <div class="fw-bold">{{ $t("views.kpi.edit.description") }}:</div>
                                <div v-html="selectedKPI.description" class="mt-3"></div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="formulas" role="tabpanel">
                        <div v-if="selectedKPI.write" class="row my-3">
                          <div class="col">
                            <button @click="openModal(false, 'formula', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#kpiEditModal">+ {{ $t("general.add") }}</button>
                          </div>
                        </div>
                        <div v-if="selectedKPI.formulas.length">
                          <table class="table">
                            <thead>
                              <tr class="border-dark">
                                <th scope="col">{{ $t("views.kpi.edit.formula") }}</th>
                                <th scope="col">{{ $t("views.kpi.edit.validFrom") }}</th>
                                <th scope="col">{{ $t("views.kpi.edit.validTo") }}</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="formula in selectedKPI.formulas" :key="'formula' + formula.id">
                                <th scope="row" class="fw-normal">
                                  <span v-for="element in formula.formula" :key="'formula' + formula.id + 'Element' + element.id" class="me-1">{{ element.name }}</span>
                                </th>
                                <td>{{ formula.validFrom }}</td>
                                <td>{{ formula.validTo }}</td>
                                <td v-if="selectedKPI.write">
                                  <div class="dropdown">
                                    <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                      <svg class="bi" width="28" height="28" fill="currentColor">
                                        <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                      </svg>
                                    </a>
                                    <ul class="dropdown-menu">
                                      <li><a @click="openModal(formula.id, 'formula', 'update')" href="#kpiEditModal" class="dropdown-item" data-bs-toggle="modal">{{ $t("general.update") }}</a></li>
                                      <li><a @click="openModal(formula.id, 'formula', 'delete')" href="#kpiDeleteModal" class="dropdown-item" data-bs-toggle="modal">{{ $t("general.delete") }}</a></li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div v-else class="mt-3 h6">{{ $t("views.kpi.detail.noFormulas") }}</div>
                      </div>
                      <div class="tab-pane fade" id="objectives" role="tabpanel">
                        <div v-if="selectedKPI.objectives.length">
                          <table class="table">
                            <thead>
                              <tr class="border-dark">
                                <th scope="col">{{ $t("views.objective.edit.name") }}</th>
                                <th scope="col">{{ $t("views.objective.edit.validFrom") }}</th>
                                <th scope="col">{{ $t("views.objective.edit.validTo") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="objective in selectedKPI.objectives" :key="'objective' + objective.id">
                                <th @click="selectObjective(objective.id)" role="button" scope="row" class="fw-normal">{{ objective.name }}</th>
                                <td>{{ new Date(objective.validFrom).toLocaleDateString(undefined, { year: "numeric", month: "long" }) }}</td>
                                <td>{{ new Date(objective.validTo).toLocaleDateString(undefined, { year: "numeric", month: "long" }) }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div v-else class="mt-3 h6">{{ $t("views.kpi.detail.noObjectives") }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <KPIPeriodsModal :key="'kpi-chart-multi-' + kpiKeyInt + selectedKPI.id" :kpi="selectedKPI"></KPIPeriodsModal>
            <KPIDeleteModal></KPIDeleteModal>
          </div>
        </div>
        <div v-else class="text-center mt-5">
          <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
          <p v-if="'nodes' in kpis && kpis.nodes.length" class="h5 mt-3">{{ $t("views.kpi.structure.helpChooseKPI") }}</p>
          <p v-else class="h5 mt-3">{{ $t("views.kpi.structure.helpCreateKPI") }}</p>
        </div>
      </div>
    </div>
    <KPIEditModal></KPIEditModal>
  </div>
</template>

<script>
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import KPITree from '../../components/monitoring/KPITree.vue'
import KPIEditModal from '../../components/monitoring/KPIEditModal.vue'
import KPIDeleteModal from '../../components/monitoring/KPIDeleteModal.vue'
import KPIPeriodSingleChart from '../../components/charts/KPIPeriodSingle.vue'
import KPIPeriodsModal from '../../components/monitoring/KPIPeriodsModal.vue'

export default {
  name: 'MonitoringManageKPI',
  data () {
    return {
      kpiKeyInt: 0,
      kpiModalElementId: false,
      kpiModalElementType: '',
      kpiModalMode: '',
      selectedElement: '',
      filterValidFrom: this.$store.state.user?.settings?.favorites?.kpiManage?.filterValidFrom ? this.$store.state.user.settings.favorites.kpiManage.filterValidFrom : new Date().getFullYear().toString() + '-01-01',
      filterValidTo: this.$store.state.user?.settings?.favorites?.kpiManage?.filterValidTo ? this.$store.state.user.settings.favorites.kpiManage.filterValidTo : (new Date().getFullYear() + 1).toString() + '-12-31',
      searchText: '',
      vocabulary: {
        timedim: {
          M: 'Monat',
          Q: 'Quartal',
          Y: 'Jahr'
        },
        aggregation: {
          S: 'Summenwertbildung',
          M: 'Mittelwertbildung',
          A: 'Endwertbildung'
        }
      }
    }
  },
  components: {
    DocsInfoLink,
    KPITree,
    KPIEditModal,
    KPIDeleteModal,
    KPIPeriodSingleChart,
    KPIPeriodsModal
  },
  computed: {
    kpis () {
      return this.$store.state.kpis.constructor === Array ? [] : this.$store.state.kpis
    },
    selectedKPI () {
      return this.$store.state.selectedKPI
    },
    getProgressStatus () {
      let progressStatus = 0
      if (this.curRecord()?.forecast && this.curRecord()?.objective) {
        progressStatus = Math.round((this.curRecord().forecast / this.curRecord().objective) * 100)
      }
      return progressStatus
    },
    statusRedRelationalOperator () {
      return this.selectedKPI.statusRed <= 100 ? '<' : '>'
    },
    statusYellowRelationalOperator () {
      return this.selectedKPI.statusRed < 100 ? '<' : '>'
    }
  },
  methods: {
    async filterKPIs (filterFrom, filterTo) {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.filterValidFrom = filterFrom
      this.filterValidTo = filterTo
      await this.$store.dispatch('getKPIs', { validFrom: filterFrom, validTo: filterTo })
      await this.$store.dispatch('favoriteObject', { isList: false, objectKey: 'kpiManage', objectValue: { filterValidFrom: filterFrom, filterValidTo: filterTo } })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    openModal (id, elementType, mode) {
      if (id) {
        this.kpiModalElementId = id
      }
      this.kpiModalElementType = elementType
      this.kpiModalMode = mode
    },
    async selectObjective (objectiveId) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getObjective', objectiveId).then(() => {
        this.$router.push({ name: 'ObjectivesManageObjective' })
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    curRecord () {
      const curRecord = this.selectedKPI.records?.find(record => {
        return record.period === new Date().getFullYear().toString()
      })
      if (curRecord) {
        const prevYearRecord = this.selectedKPI.records?.find(record => {
          return parseInt(record.period) === parseInt(curRecord.period) - 1
        })
        if (prevYearRecord) {
          curRecord.actualPrev = prevYearRecord.actual
        }
        this.selectedKPI.displayedPeriod = curRecord.period
        return curRecord
      } else {
        return {}
      }
    },
    unitDisplay (kpi, displayMode) {
      const unit = this.$store.state.kpiUnits.find(unit => {
        return unit.id === this.selectedKPI.unit
      })
      if (unit) {
        if (displayMode === 'short') {
          return unit.name
        } else {
          return unit.name + ' (' + unit.unit + ')'
        }
      } else {
        return 'n/a'
      }
    }
  },
  async created () {
    this.$store.commit('SET_LOADING_STATUS', true)
    await this.$store.dispatch('getKPIs', { validFrom: this.filterValidFrom, validTo: this.filterValidTo })
    this.$store.commit('SET_LOADING_STATUS', false)
    if (Object.keys(this.selectedKPI).length) {
      await this.$store.dispatch('getKPI', { mode: 'manage', id: this.selectedKPI.id })
    }
    this.kpiKeyInt++
    await this.$store.dispatch('getKPIUnits')
    await this.$store.dispatch('getEmployees', { mode: 'default' })
  }
}
</script>

<style scoped>
.kpi-chart {
  max-height: 200px;
}
.progress-label {
  transform: translate(-50%, -50%);
}
</style>
