<template>
  <div v-if="!isLoading && isTenantNotActivated" class="text-center border-bottom border-2 border-danger py-1 small">
    {{ $t("general.activateTenant") }}
  </div>
</template>

<script>
export default {
  name: 'TenantNotActivated',
  computed: {
    isLoading () {
      return this.$store.state.loadingStatus
    },
    isTenantNotActivated () {
      return this.$store.state.loginStatus && this.$store.state.user.role === 10387 && !this.$store.state.tenant.activated ? 1 : 0
    }
  }
}
</script>
