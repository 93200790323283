<template>
  <header class="bg-primary border-bottom border-light">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <router-link :to="isAuthenticated ? '/monitoring/kpis' : '/'" class="navbar-brand text-light" :class="breadcrumbs ? 'border-end border-2 pe-3' : ''">
          <svg id="navbar-logo" class="bi pb-1 me-2" width="30" height="30" fill="currentColor">
            <use xlink:href="@/assets/icons/icons.svg#nut-fill"/>
          </svg>
          <span v-if="isAuthenticated">{{ userOrg }}</span>
          <span v-else>
            <span v-if="!isMultiTenant">{{ tenantName }} @ </span>
            focusMT
          </span>
        </router-link>
        <nav v-if="breadcrumbs" id="nav-breadcrumbs" aria-label="breadcrumb">
          <ol class="breadcrumb my-auto">
            <li v-for="breadcrumb, index in breadcrumbs" :key="'breadcrumb' + index" class="breadcrumb-item text-light">{{ breadcrumb }}</li>
          </ol>
        </nav>
        <button class="navbar-toggler pe-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <svg class="bi" width="30" height="30" fill="#f8f9fa">
            <use xlink:href="@/assets/icons/icons.svg#list"/>
          </svg>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul v-if="isAuthenticated" class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown my-auto px-2">
              <a class="nav-link dropdown text-light" href="#" id="navbarDropdownObjective" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{ $t("views.nav.headingObjectives") }}</a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownObjective">
                <li><router-link to="/objectives/manage/objectives" class="dropdown-item">{{ $t("views.nav.manageObjectives") }}</router-link></li>
                <li><hr class="dropdown-divider"></li>
                <li><router-link @click="setKPIRecordMode('objective')" to="/monitoring/manage/kpis/records" class="dropdown-item">{{ $t("views.nav.recordObjective") }}</router-link></li>
                <li><hr class="dropdown-divider"></li>
                <li><router-link to="/objectives/x-matrix" class="dropdown-item">{{ $t("views.nav.viewXMatrix") }}</router-link></li>
              </ul>
            </li>
            <li class="nav-item dropdown my-auto px-2">
              <a class="nav-link dropdown text-light" href="#" id="navbarDropdownMonitoring" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{ $t("views.nav.headingMonitoring") }}</a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMonitoring">
                <li><router-link to="/monitoring/kpis" class="dropdown-item">{{ $t("views.nav.viewKPIs") }}</router-link></li>
                <li><router-link to="/monitoring/manage/kpis" class="dropdown-item">{{ $t("views.nav.manageKPIs") }}</router-link></li>
                <li><hr class="dropdown-divider"></li>
                <li><router-link @click="setKPIRecordMode('actual')" to="/monitoring/manage/kpis/records" class="dropdown-item">{{ $t("views.nav.recordActualForecast") }}</router-link></li>
                <li><router-link @click="setKPIRecordMode('plan')" to="/monitoring/manage/kpis/records" class="dropdown-item">{{ $t("views.nav.recordPlan") }}</router-link></li>
                <li><hr class="dropdown-divider"></li>
                <li><router-link to="/monitoring/kpis/deviations" class="dropdown-item">{{ $t("views.nav.deviations") }}</router-link></li>
                <li><router-link to="/monitoring/kpis/changes" class="dropdown-item">{{ $t("views.nav.changes") }}</router-link></li>
                <li v-if="role === 10387"><hr class="dropdown-divider"></li>
                <li v-if="role === 10387"><router-link to="/monitoring/manage/kpis/units" class="dropdown-item">{{ $t("views.nav.manageKPIUnits") }}</router-link></li>
              </ul>
            </li>
            <li class="nav-item dropdown my-auto px-2">
              <a class="nav-link dropdown text-light" href="#" id="navbarDropdownProject" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{ $t("views.nav.headingProjects") }}</a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownProject">
                <li><router-link to="/projects/manage/projects" class="dropdown-item">{{ $t("views.nav.manageProjects") }}</router-link></li>
              </ul>
            </li>
            <li class="nav-item dropdown my-auto ps-2 pe-1">
              <a class="nav-link dropdown text-light" href="#" id="navbarDropdownOrg" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{ $t("views.nav.headingOrg") }}</a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownOrg">
                <li><router-link to="/organization/manage/org-units" class="dropdown-item">{{ $t("views.nav.manageOrgs") }}</router-link></li>
                <li v-if="role === 10387"><router-link to="/organization/manage/employees" class="dropdown-item">{{ $t("views.nav.manageEmployees") }}</router-link></li>
              </ul>
            </li>
            <li class="border-start border-2 mx-2"></li>
            <li v-if="role === 10387" class="nav-item dropdown my-auto ps-1">
              <a class="nav-link dropdown text-light" href="#" id="navbarDropdownSettings" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <svg class="bi" width="22" height="22" fill="currentColor">
                  <use xlink:href="@/assets/icons/icons.svg#gear-fill"/>
                </svg>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownSettings">
                <li><router-link to="/settings/permissions" class="dropdown-item">{{ $t("views.nav.managePermissions") }}</router-link></li>
                <li><router-link to="/settings/tenant" class="dropdown-item">{{ $t("views.nav.manageTenant") }}</router-link></li>
              </ul>
            </li>
            <li class="nav-item dropdown my-auto">
              <a class="nav-link dropdown text-light pe-0" href="#" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <svg class="bi" width="28" height="28" fill="currentColor">
                  <use xlink:href="@/assets/icons/icons.svg#person-fill"/>
                </svg>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownUser">
                <li><router-link to="/account/manage/user" class="dropdown-item">{{ $t("views.nav.manageAccount") }}</router-link></li>
                <li><hr class="dropdown-divider"></li>
                <li v-for="org in userOrgs" :key="org.id">
                  <button @click="setOrg(org.id)" class="dropdown-item">
                    <span :class="org.active ? 'border-start border-primary border-3 me-1' : 'border-start border-3 me-1'"></span>
                    <span>{{ org.name }}</span>
                  </button>
                </li>
                <li><hr class="dropdown-divider"></li>
                <li><button @click="logout" class="dropdown-item">{{ $t("views.nav.logout") }}</button></li>
                <li><hr class="dropdown-divider"></li>
                <li><a href="https://focusmt.de/docs" target="_blank" class="dropdown-item">{{ $t("views.nav.docs") }}</a></li>
                <li><a href="mailto:support@focusmt.de" class="dropdown-item">{{ $t("views.nav.support") }}</a></li>
                <li class="dropdown-item"><small>v23.01</small></li>
              </ul>
            </li>
          </ul>
          <ul v-if="!isAuthenticated" class="navbar-nav mb-2 mb-lg-0" :class="['LoginPage', 'RegisterPage'].includes(currentRoute) ? 'ms-auto' : ''">
            <li v-if="isMultiTenant" class="nav-item"><router-link to="/register" class="nav-link text-light pe-3 mt-3 mt-lg-0">{{ $t("views.nav.register") }}</router-link></li>
            <li class="nav-item"><router-link to="/login" class="nav-link text-light pe-0 mt-3 mt-lg-0">{{ $t("views.nav.login") }}</router-link></li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
  name: 'TopNavigation',
  computed: {
    isAuthenticated () {
      return this.$store.state.user.email
    },
    isMultiTenant () {
      return !!(this.$store.state.tenantMode === 'Multi')
    },
    tenantName () {
      return this.$store.state.tenantName
    },
    currentRoute () {
      return useRoute().name
    },
    kpiRecordMode () {
      return this.$store.state.kpiRecordMode
    },
    breadcrumbs () {
      const breadcrumbs = {
        AccountManageUser: [this.$t('views.nav.manageAccount')],
        SettingsManagePermission: ['Settings', this.$t('views.nav.managePermissions')],
        SettingsManageTenant: ['Settings', this.$t('views.nav.manageTenant')],
        OrgManageOrgUnit: [this.$t('views.nav.headingOrg'), this.$t('views.nav.manageOrgs')],
        OrgManageEmployee: [this.$t('views.nav.headingOrg'), this.$t('views.nav.manageEmployees')],
        MonitoringKPI: [this.$t('views.nav.headingMonitoring'), this.$t('views.nav.viewKPIs')],
        MonitoringKPIChange: [this.$t('views.nav.headingMonitoring'), this.$t('views.nav.changes')],
        MonitoringKPIDeviation: [this.$t('views.nav.headingMonitoring'), this.$t('views.nav.deviations')],
        MonitoringManageKPI: [this.$t('views.nav.headingMonitoring'), this.$t('views.nav.manageKPIs')],
        MonitoringManageKPIUnit: [this.$t('views.nav.headingMonitoring'), this.$t('views.nav.manageKPIUnits')],
        ObjectivesXMatrix: [this.$t('views.nav.headingObjectives'), this.$t('views.nav.viewXMatrix')],
        ObjectivesManageObjective: [this.$t('views.nav.headingObjectives'), this.$t('views.nav.manageObjectives')],
        ProjectsManageProject: [this.$t('views.nav.headingProjects'), this.$t('views.nav.manageProjects')]
      }

      if (this.currentRoute === 'MonitoringManageKPIRecord') {
        let kpiRecordMode = ''
        if (this.kpiRecordMode === 'actual') {
          kpiRecordMode = this.$t('views.nav.recordActualForecast')
          return [this.$t('views.nav.headingMonitoring'), kpiRecordMode]
        } else if (this.kpiRecordMode === 'plan') {
          kpiRecordMode = this.$t('views.nav.recordPlan')
          return [this.$t('views.nav.headingMonitoring'), kpiRecordMode]
        } else if (this.kpiRecordMode === 'objective') {
          kpiRecordMode = this.$t('views.nav.recordObjective')
          return [this.$t('views.nav.headingMonitoring'), kpiRecordMode]
        }
        return [this.$t('views.nav.headingMonitoring')]
      } else if (this.currentRoute in breadcrumbs) {
        return breadcrumbs[this.currentRoute]
      } else {
        return false
      }
    },
    role () {
      return this.$store.state.user.role
    },
    userOrgs () {
      return this.$store.state.userOrgs
    },
    userOrg () {
      return this.$store.state.userOrg
    }
  },
  methods: {
    async setOrg (id) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('setOrg', { org: id })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    setKPIRecordMode (kpiRecordMode) {
      this.$store.dispatch('setKPIRecordMode', kpiRecordMode)
    },
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style scoped>
nav button {
  border: none;
}
.navbar .navbar-brand {
  max-height: 40px;
}
#nav-breadcrumbs {
  --bs-breadcrumb-divider: '>';
}
#nav-breadcrumbs .breadcrumb-item::before {
  color: #f8f9fa;
}
</style>
